<template>
  <div class="StockOverview" ref="domWidth" :style="{ width: domWidth }">
    <!-- <div :style="cssObj"> -->
    <div class="flexBox">
      <div style="font-size: 18px;">
        {{ FirstDate ? '' : monthDate
        }}<span v-show="!FirstDate">月份</span>业绩报表
      </div>
      <!--FirstDate+'-'+EndDate <a-month-picker style="width: 120px" @change="monthChange" :disabledDate="disabledDate" :defaultValue="moment(censusMonth, monthFormat)" :format="monthFormat" placeholder="月份" /> -->
      <a-range-picker @change="onChange" style="width:260px;" />
    </div>
    <a-row>
      <a-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="12"
        style="margin-top:10px;"
      >
        <div class="carBox box" style="font-size:48px;padding-left:30px;">
          {{ totalPlan }}
          <span style="bottom:10px;right:15px;">全国总业绩</span>
        </div>
      </a-col>
      <a-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="12"
        style="margin-top:10px;"
      >
        <div
          class="carBox flexBox mleft"
          style="font-size:28px;height:112px;border:1px solid transparent"
        >
          <div
            v-for="(list, k) in plan"
            :key="k"
            class="carBox box "
            :style="{ 'margin-left': k == 0 ? '0px' : '20px' }"
          >
            {{ list.count }}<span>{{ list.item }}</span>
            <div class="progressBox">
              <div style="position:relative;font-size:13px;">
                <span
                  :style="{
                    'margin-left':
                      (list.percent * 100 > 80 ? 83 : list.percent * 100) + '%',
                    color: k == 0 ? '#5076FA' : k == 1 ? '#FED200' : '#FE4152'
                  }"
                  >{{ (list.percent * 100) | decimal_two }}%</span
                >
                <div
                  class="progressBar bar"
                  :style="{
                    width:
                      (list.percent * 100 > 100 ? 100 : list.percent * 100) +
                      '%',
                    'background-color':
                      k == 0 ? '#5076FA' : k == 1 ? '#FED200' : '#FE4152'
                  }"
                ></div>
                <div class="bar"></div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div class="chartBox">
          <div class="title">
            <span>产品销售简报</span>
            <!--<div style="position:absolute;top:34px;left:0px;">-->
            <!--<span v-for="(list,k) in briefingTable" :key="k" :style="{'margin-right': k==list.length?'0px':'24px',float:'left',}"> -->
            <!--<b :style="{color:COLOR_PLATE_16[k]}">&bull;</b>-->
            <!--<span class="type_class" >{{list.item}}</span><br/>-->
            <!--<span style="margin-left:14px;" >{{list.count}}</span>-->
            <!--</span>-->
            <!--</div>-->
          </div>
          <div class="flex">
            <div style="width: 250px;">
              <table style="width:100%;font-size:14px;font-weight:400;">
                <!--<thead style="line-height:40px;">-->
                <!--<th style="width:30px"></th>-->
                <!--<th style="width:180px">名称</th>-->
                <!--<th>业绩</th>-->
                <!--</thead>-->
                <tbody class="type_class">
                  <tr
                    v-for="(list, k) in briefingTable"
                    :key="k"
                    style="line-height:19px;"
                  >
                    <td style="min-width:30px;">
                      <span
                        class="widthBar"
                        :style="{ 'background-color': COLOR_PLATE_16[k] }"
                      ></span>
                    </td>
                    <td
                      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    >
                      {{ list.item }}
                    </td>
                    <td style="min-width:30px;">{{ list.count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="briefing" style="flex: 1"></div>
          </div>
        </div>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox mleft"
          ref="TopSelling"
          :style="{ position: 'relative', overflow: 'hidden' }"
        >
          <div class="title">
            <span
              >销售数量排行<i style="color:#f5222d;font-size: 20px;"
                >TOP10</i
              ></span
            >
            <table
              style="position:absolute;top:34px;left:0px;width:250px;font-size:14px;font-weight:400;"
            >
              <thead style="line-height:40px;">
                <th style="width:30px"></th>
                <th style="width:180px">名称</th>
                <th>数量</th>
              </thead>
              <tbody class="type_class">
                <tr
                  v-for="(list, k) in TopSellingData"
                  :key="k"
                  style="line-height:28px;"
                >
                  <td style="min-width:30px;">
                    <span
                      class="widthBar"
                      :style="{ 'background-color': COLOR_PLATE_16[k] }"
                    ></span>
                  </td>
                  <td
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >
                    {{ list.item }}
                  </td>
                  <td style="min-width:30px;">{{ list.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            id="TopSelling"
            :style="{ width: TopSellingWidth, 'margin-top': '8%' }"
          ></div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox"
          ref="TopSelling"
          :style="{ position: 'relative', overflow: 'hidden' }"
        >
          <div class="title">
            <span
              >销售金额排行<i style="color:#f5222d;font-size: 20px;"
                >TOP10</i
              ></span
            >
            <table
              style="position:absolute;top:34px;left:0px;width:250px;font-size:14px;font-weight:400;"
            >
              <thead style="line-height:40px;">
                <th style="width:30px"></th>
                <th style="width:180px">名称</th>
                <th>金额</th>
              </thead>
              <tbody class="type_class">
                <tr
                  v-for="(list, k) in TopMoneySellingData"
                  :key="k"
                  style="line-height:28px;"
                >
                  <td style="min-width:30px;">
                    <span
                      class="widthBar"
                      :style="{ 'background-color': COLOR_PLATE_16[k] }"
                    ></span>
                  </td>
                  <td
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >
                    {{ list.item }}
                  </td>
                  <td style="min-width:30px;">￥{{ list.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            id="TopMoneySelling"
            :style="{ width: TopSellingWidth, 'margin-top': '8%' }"
          ></div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox mleft"
          :style="{ position: 'relative', overflow: 'hidden' }"
        >
          <div class="title">
            <div style="display:flex;justify-content:space-between;">
              <span
                >直营中心产品业绩排行<i style="color:#f5222d;font-size: 20px;"
                  >TOP10</i
                ></span
              >
              <a-button
                type="primary"
                size="small"
                ghost
                @click="showFn('money')"
                >产品业绩</a-button
              >
            </div>
          </div>
          <div id="TopCenter"></div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox overBox "
          style="position:relative;overflow: hidden;"
        >
          <div class="title">
            <div style="display:flex;justify-content:space-between">
              <span>产品库存占比</span>
              <a-button
                type="primary"
                size="small"
                ghost
                @click="showFn('ratios')"
                >产品库存</a-button
              >
            </div>

            <table
              style="position: absolute;
                                top: 34px;
                                right: -26px;
                                width: 45%;
                                min-width: 249px;
                                font-size: 14px;
                                font-weight: 400;"
            >
              <thead style="line-height:40px;">
                <th style="min-width:100px;text-indent:12px;">名称</th>
                <th>采购价</th>
                <th>销售价</th>
              </thead>
              <tbody class="type_class">
                <tr
                  v-for="(list, k) in StockMoneyData"
                  :key="k"
                  style="line-height:28px;"
                >
                  <td>
                    <b :style="{ color: COLOR_PLATE_16[k] }">&bull;</b
                    ><span class="type_class"> {{ list.item }}</span>
                  </td>
                  <td>{{ list.PurchasePrice }}</td>
                  <td>{{ list.SellPrice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            id="InventoryRatio"
            :style="{ width: (TopSellingWidth * 2) / 3 }"
          ></div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox overBox mleft"
          style="background: rgb(240, 240, 248);position:relative;"
        >
          <div
            style="width:100%;position:absolute;top: 0;left: 0px;"
            class="flexBox"
          >
            <div
              class="overBox"
              style="color:#666666; background-color: #ffffff;  padding: 20px 28px; "
            >
              <div class="title">
                <span>产品库存</span>
              </div>
              <div class="flexBox">
                <div
                  class="groupBox"
                  v-for="(list, k) in productNums"
                  :key="k"
                  :style="{
                    'border-left': k == 1 ? '1px solid #ebebeb' : '0px'
                  }"
                >
                  <span>{{ list.item }}</span>
                  <div>{{ list.count }}</div>
                </div>
              </div>
              <div class="totalNums">
                产品种类数量 &nbsp;
                <span class="nums">{{ totalStockMoney }}</span>
              </div>
            </div>
            <div
              class="overBox"
              style="color:#666666;margin-top:0px;margin-left:20px; background-color: #ffffff;"
            >
              <div class="title" style="margin-top: 20px;margin-left:28px; ">
                <span>产品赠送费用</span>
              </div>
              <div class="totalNums">
                总赠送金额 &nbsp;
                <span class="nums">{{ this.moneyTotal }}</span>
              </div>
              <div id="moneyTotal"></div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
        <div
          class="chartBox "
          :style="{ position: 'relative', overflow: 'hidden' }"
        >
          <div class="title">
            <div style="display:flex;justify-content:space-between;">
              <span
                >加盟中心产品购买排行<i style="color:#f5222d;font-size: 20px;"
                  >TOP10</i
                ></span
              >
              <a-button
                type="primary"
                size="small"
                ghost
                @click="showFn('joinmoney')"
                >产品购买</a-button
              >
            </div>
          </div>
          <div id="joinTopCenter"></div>
        </div>
      </a-col>
    </a-row>
    <a-drawer
      :title="showName"
      :width="580"
      @close="onClose"
      :visible="showTop"
      placement="right"
      :closable="true"
    >
      <a-table
        v-if="showName == '中心产品库存统计'"
        :columns="columns"
        :dataSource="dataList"
        :pagination="false"
        :customRow="rowClick"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="purchasePrice" slot-scope="PurchasePrice">
          <div><a-statistic :precision="2" :value="PurchasePrice" /></div>
        </template>
        <template slot="sellPrice" slot-scope="SellPrice">
          <div><a-statistic :precision="2" :value="SellPrice" /></div>
        </template>
        <template slot="count" slot-scope="count">
          <div><a-statistic :value="count" /></div>
        </template>
      </a-table>
      <a-table
        v-else-if="showName == '直营中心产品业绩统计'"
        :columns="columsTotal"
        :dataSource="dataTotalList"
        :pagination="false"
        :customRow="rowClick"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="plan1" slot-scope="Plan1, obj">
          <div>
            <!-- <div :style="{'font-weight':'bold',color:obj.count/Plan1>1?'#5076FA':''}"> -->
            <span
              >{{
                obj.count / Plan1 > 1
                  ? 100
                  : Math.ceil((obj.count / Plan1) * 100)
              }}%</span
            >
            <p>{{ Plan1 }}万</p>
          </div>
        </template>
        <template slot="plan2" slot-scope="Plan2, obj">
          <div>
            <!-- <div :style="{'font-weight':'bold',color:obj.count/Plan2>1?'#fed200':''}"> -->
            <span
              >{{
                obj.count / Plan2 > 1
                  ? 100
                  : Math.ceil((obj.count / Plan2) * 100)
              }}%</span
            >
            <p>{{ Plan2 }}万</p>
          </div>
        </template>
        <template slot="plan3" slot-scope="Plan3, obj">
          <div>
            <!-- <div :style="{'font-weight':'bold',color:obj.count/Plan3>1?'#f5222d':''}"> -->
            <span>{{ Math.ceil((obj.count / Plan3) * 100) }}%</span>
            <p>{{ Plan3 }}万</p>
          </div>
        </template>
        <template slot="count" slot-scope="count"> {{ count }}万 </template>
      </a-table>
      <a-table
        v-else
        :columns="joincolumsTotal"
        :dataSource="joindataTotalList"
        :pagination="false"
        :customRow="rowClick"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="DirectSelling" slot-scope="DirectSelling">
          <div>
            <!-- <div :style="{'font-weight':'bold',color:obj.count/Plan1>1?'#5076FA':''}"> -->
            {{ DirectSelling }}{{ DirectSelling == 0 ? '' : '万' }}
          </div>
        </template>
        <template slot="PurchaseMoney" slot-scope="PurchaseMoney">
          <div>
            <!-- <div :style="{'font-weight':'bold',color:obj.count/Plan2>1?'#fed200':''}"> -->
            {{ PurchaseMoney }}{{ PurchaseMoney == 0 ? '' : '万' }}
          </div>
        </template>

        <template slot="TotalMoney" slot-scope="TotalMoney">
          {{ TotalMoney }}{{ TotalMoney == 0 ? '' : '万' }}
        </template>
      </a-table>
    </a-drawer>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
    <!-- </div> -->
  </div>
</template>
<script>
import G2 from '@antv/g2'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import { days } from '@/assets/js/public_ren'

function delFont (val) {
  let str
  if (val.indexOf('筹备中') > 1) {
    let index = val.indexOf('筹备中') - 1
    str = val.substr(0, index)
  } else {
    str = val
  }
  return str.length > 7 ? str.substr(0, 7) : str
}
const COLOR_PLATE_16 = [
  '#1890FF',
  '#41D9C7',
  '#2FC25B',
  '#FACC14',
  '#E6965C',
  '#223273',
  '#7564CC',
  '#8543E0',
  '#5C8EE6',
  '#13C2C2',
  '#5CA3E6',
  '#3436C7',
  '#B381E6',
  '#F04864',
  '#D598D9',
  '#ddd',
  '#666'
]
const columns = [
  {
    title: '中心名称',
    key: 'SchoolName',
    dataIndex: 'SchoolName',
    fixed: 'left'
  },
  {
    title: '采购价',
    width: 120,
    key: 'PurchasePrice',
    dataIndex: 'PurchasePrice',
    scopedSlots: { customRender: 'purchasePrice' },
    fixed: 'left'
  },
  {
    title: '销售价',
    width: 120,
    key: 'SellPrice',
    dataIndex: 'SellPrice',
    scopedSlots: { customRender: 'sellPrice' },
    fixed: 'left'
  },
  {
    title: '库存数量',
    width: 90,
    key: 'count',
    dataIndex: 'count',
    scopedSlots: { customRender: 'count' },
    fixed: 'left'
  }
]
const columsTotal = [
  {
    title: '中心名称',
    key: 'item',
    key: 'item',
    dataIndex: 'item',
    fixed: 'left'
  },
  {
    title: '一标',
    width: 85,
    key: 'Plan1',
    dataIndex: 'Plan1',
    scopedSlots: { customRender: 'plan1' },
    fixed: 'left'
  },
  {
    title: '二标',
    width: 85,
    key: 'Plan2',
    dataIndex: 'Plan2',
    scopedSlots: { customRender: 'plan2' },
    fixed: 'left'
  },
  {
    title: '三标',
    width: 85,
    key: 'Plan3',
    dataIndex: 'Plan3',
    scopedSlots: { customRender: 'plan3' },
    fixed: 'left'
  },
  {
    title: '销售金额',
    width: 90,
    key: 'count',
    dataIndex: 'count',
    scopedSlots: { customRender: 'count' },
    fixed: 'left'
  }
]
const joincolumsTotal = [
  {
    title: '中心名称',
    key: 'SchoolName',
    key: 'SchoolName',
    dataIndex: 'SchoolName',
    fixed: 'left'
  },
  {
    title: '采销',
    width: 110,
    key: 'PurchaseMoney',
    dataIndex: 'PurchaseMoney',
    scopedSlots: { customRender: 'PurchaseMoney' },
    fixed: 'left'
  },
  {
    title: '直销',
    width: 100,
    key: 'DirectSelling',
    dataIndex: 'DirectSelling',
    scopedSlots: { customRender: 'DirectSelling' },
    fixed: 'left'
  },
  // { title: '三标',width: 85,key:'Plan3', dataIndex: 'Plan3',scopedSlots:{customRender: 'plan3'},fixed: 'left'  },
  {
    title: '销售金额',
    width: 120,
    key: 'TotalMoney',
    dataIndex: 'TotalMoney',
    scopedSlots: { customRender: 'TotalMoney' },
    fixed: 'left'
  }
]
export default {
  name: 'StockOverview',
  data () {
    return {
      showTop: false,
      dataList: [],
      showName: '中心产品库存统计',
      columns: columns,
      dataTotalList: [], //直营中心
      joindataTotalList: [], //加盟中心
      columsTotal: columsTotal,
      joincolumsTotal: joincolumsTotal,
      spinning: false,
      clientH: '400px',
      monthFormat: 'YYYY-MM',
      censusMonth: '2019-11',
      monthDate: '08',
      FirstDate: '',
      EndDate: '',

      nums: 46, //模拟数据 进度条
      COLOR_PLATE_16,

      briefingData: [],
      briefingTable: [],
      totalBriefing: '',
      TopSellingData: [], //数量
      TotalTopSelling: '',
      TopMoneySellingData: [], //金额
      TopMoneySelling: '',

      InventoryRatioData: [],
      totalnventoryRatio: '',
      moneyTotalData: [],
      moneyTotal: '',
      StockMoneyData: [], //库存
      totalStockMoney: '',
      StockNums: '',
      productNums: [],

      schoolSaleList: [], //中心排行
      joinschoolSaleList: [], //加蒙中心排行
      totalPlan: '', //当前业绩
      plan: [], //三标

      TopSellingWidth: '300px',
      domWidth: 800,
      cssObj: {}
    }
  },
  created () {
    //获取当前月

    let myDate = new Date()
    let year = myDate.getFullYear()
    let month = myDate.getMonth() + 1
    if (month === 0) {
      month = 12
      year = year - 1
    } else if (month < 10) {
      month = '0' + month
    }
    this.censusMonth = year + '-' + month
    this.monthDate = month //+1>12?1:month+1;
    this.ajaxInitFn()
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 156 + 'px'
    let that = this
    this.$nextTick(function () {
      // console.log(that.$refs.TopSelling.clientWidth) + 'px';
      that.TopSellingWidth = that.$refs.TopSelling.clientWidth - 340 + 'px'
      that.domWidth = that.$refs.domWidth.clientWidth
    })
    if (that.domWidth > 1200) {
      this.cssObj = { 'min-height': this.clientH }
    } else {
      this.cssObj = {
        'min-height': this.clientH,
        'min-width': '1200px',
        border: '1px solid red'
      }
    }
  },
  methods: {
    moment,
    onClose () {
      //关闭侧边
      this.showTop = false
    },
    showFn (type) {
      if (type == 'ratios') {
        //占比
        this.showName = '中心产品库存统计'
      }
      if (type == 'money') {
        this.showName = '直营中心产品业绩统计'
      }
      if (type == 'joinmoney') {
        this.showName = '加盟中心产品购买统计'
      }
      this.showTop = true
    },
    // 点击table行
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    },
    disabledDate (current) {
      // 未来月份不可选
      return current && current > moment().endOf('day')
    },
    // monthChange(val){
    //      let census_month = new Date(val._d);
    //      let month = census_month.getFullYear()+'-'+(census_month.getMonth()+1<10?'0'+(census_month.getMonth()+1):census_month.getMonth()+1)
    //      this.censusMonth = month;
    //      this.monthDate = Number(month.substr(5,2));
    //      this.ajaxInitFn();
    // },
    onChange (date, dateString) {
      // console.log(date, dateString);
      this.FirstDate = dateString[0]
      this.EndDate = dateString[1]
      this.ajaxInitFn()
    },
    ajaxInitFn () {
      let that = this
      that.spinning = true
      that.$axios.post(
        9972,
        {
          //  Month:that.censusMonth,
          FirstDate: that.FirstDate ? that.FirstDate : days('month') + '-01',
          EndDate: that.EndDate ? that.EndDate : days()
        },
        res => {
          //  console.log(res.data)
          document.getElementById('TopSelling').innerHTML = ' '
          that.TopSellingData = res.data.GoodsList
          that.TotalTopSelling = res.data.TotalGoodsNum
          // document.getElementById('InventoryRatio').innerHTML=' ';
          that.InventoryRatioData = res.data.Stock
          that.moneyTotalData = res.data.HandselMoney
          document.getElementById('moneyTotal').innerHTML = ' '
          that.moneyTotal = res.data.TotalHandselMoney

          that.TopSellingFn(that.TopSellingData)
          that.moneyTotalFn(that.moneyTotalData)
          that.schoolSaleList = res.data.SchoolSaleList
          let array = res.data.SchoolSaleList
          for (let i = 0; i < array.length; i++) {
            array[i].item = delFont(array[i].item)
          }
          document.getElementById('TopCenter').innerHTML = ' '
          that.TopCenterFn(array)

          that.TopMoneySellingData = res.data.GoodsMoneyList
          that.TopMoneySelling = res.data.TotalGoodsMoney
          document.getElementById('TopMoneySelling').innerHTML = ' '
          that.TopMoneySellingFn(that.TopMoneySellingData)

          that.spinning = false
          that.StockMoneyData = res.data.Stock
          that.totalStockMoney = res.data.GoodsNum
          that.StockNums = res.data.StockTotalNum
          that.productNums = res.data.StockMoney
          document.getElementById('InventoryRatio').innerHTML = ' '
          that.InventoryRatioFn(that.StockMoneyData)

          that.dataList = []
          that.dataList = res.data.SchoolStockList
          that.dataTotalList = []
          that.dataTotalList = res.data.SchoolSaleTotalList
          that.joindataTotalList = []
          that.joindataTotalList = res.data.JoinSchoolTotalList
          // console.log(res.data.JoinSchoolList)
          //加盟top10join
          that.joinschoolSaleList = res.data.JoinSchoolList
          let joinArray = res.data.JoinSchoolList.reverse()
          for (let i = 0; i < joinArray.length; i++) {
            joinArray[i].item = delFont(joinArray[i].SchoolName)
          }
          document.getElementById('joinTopCenter').innerHTML = ' '
          // console.log(joinArray)
          that.joinTopCenterFn(joinArray)
        }
      )
      that.$axios.post(
        9973,
        {
          //  Month:that.censusMonth,
          FirstDate: that.FirstDate ? that.FirstDate : days('month') + '-01',
          EndDate: that.EndDate ? that.EndDate : days()
        },
        res => {
          // console.log(res.data)
          document.getElementById('briefing').innerHTML = ' '
          that.briefingTable = res.data.ShopTotal
          that.briefingData = res.data.Shop
          that.totalPlan = res.data.TotalMoney
          that.plan = res.data.Plan
          that.briefingFn(that.briefingData)
        }
      )
      // //查库存
      // that.$axios.post(9972,{Month:that.censusMonth},res=>{
      //     that.spinning = false;
      //     console.log(res.data)
      //     that.StockMoneyData = res.data.Stock;
      //     that.totalStockMoney = res.data.GoodsNum;
      //     that.StockNums = res.data.StockTotalNum;
      //     that.productNums = res.data.StockMoney;
      //     document.getElementById('InventoryRatio').innerHTML=' ';
      //     that.InventoryRatioFn(that.StockMoneyData);
      // })
    },
    briefingFn (data) {
      let chart = new G2.Chart({
        container: 'briefing',
        forceFit: true,
        height: 340,
        animate: false
      })

      // chart.legend({
      //    position: 'top',
      // });
      chart.legend(false)
      chart.source(data, {
        percent: {
          formatter: val => {
            val = Math.ceil((val * 10000) / 1) / 100 + '%'
            return val
          }
        }
      })
      chart.coord('theta', {
        radius: 0.8,
        innerRadius: 0.6
      })
      chart.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      console.log(this.totalPlan)
      // 辅助文本
      chart.guide().html({
        position: ['50%', '50%'],
        html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">合计<br><span style="color:#8c8c8c;font-size:18px">${this.totalPlan}</span></div>`,
        alignX: 'middle',
        alignY: 'middle'
      })
      let interval = chart
        .intervalStack()
        .position('percent')
        .color('item', COLOR_PLATE_16)
        .label('percent', {
          formatter: (val, item) => {
            return `${item.point.item}: ${val}`
          }
        })
        .tooltip('item*count', (item, count) => {
          // percent = Math.ceil((percent * 10000)/1)/100 + '%';
          return {
            name: item,
            value: count
          }
        })
        .style({
          lineWidth: 1,
          stroke: '#fff'
        })
      chart.render()
      interval.setSelected(data[0])
    },
    TopCenterFn (data) {
      data
      const chart = new G2.Chart({
        container: 'TopCenter',
        forceFit: true,
        height: 380
      })
      chart.source(data)
      chart.axis('item', {
        label: {
          offset: 12,
          textStyle: {
            fontSize: 12, // 文本大小
            textAlign: 'right', // 文本对齐方式
            fill: '#999', // 文本颜色
            rotate: -40
          }
        }
      })
      // chart.tooltip(true, {
      // showTitle: false,
      // inPlot: false,
      // });
      chart.coord().transpose()
      chart
        .interval()
        .position('item*count')
        .label('count')
      chart.render()
    },
    joinTopCenterFn (data) {
      data
      const chart = new G2.Chart({
        container: 'joinTopCenter',
        forceFit: true,
        height: 380
      })
      chart.source(data)
      chart.axis('item', {
        label: {
          offset: 12,
          textStyle: {
            fontSize: 12, // 文本大小
            textAlign: 'right', // 文本对齐方式
            fill: '#999', // 文本颜色
            rotate: -40
          }
        }
      })
      chart.coord().transpose()
      chart
        .interval()
        .position('item*TotalMoney')
        .label('TotalMoney')
      chart.render()
    },
    TopMoneySellingFn (data) {
      let topSelling = new G2.Chart({
        container: 'TopMoneySelling',
        forceFit: true,
        height: 330,
        animate: false
      })

      // topSelling.legend({
      //    position: 'top',
      // });
      topSelling.legend(false)
      topSelling.source(data, {
        percent: {
          formatter: val => {
            val = Math.ceil((val * 10000) / 1) / 100 + '%'
            return val
          }
        }
      })
      topSelling.coord('theta', {
        radius: 0.8,
        innerRadius: 0.6
      })
      topSelling.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      // 辅助文本
      topSelling.guide().html({
        position: ['50%', '50%'],
        html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">合计<br><span style="color:#8c8c8c;font-size:20px">${this.TopMoneySelling}</span></div>`,
        alignX: 'middle',
        alignY: 'middle'
      })
      let interval = topSelling
        .intervalStack()
        .position('percent')
        .color('item', COLOR_PLATE_16)
        .label('percent', {
          formatter: (val, item) => {
            return `${item.point.item}:\n ${val}`
          }
        })
        .tooltip('item*count', (item, count) => {
          // percent = Math.ceil((percent * 10000)/1)/100 + '%';
          return {
            name: item,
            value: count
          }
        })
        .style({
          lineWidth: 1,
          stroke: '#fff'
        })
      topSelling.render()
      interval.setSelected(data[0])
    },

    TopSellingFn (data) {
      let topSelling = new G2.Chart({
        container: 'TopSelling',
        forceFit: true,
        height: 330,
        animate: false
      })

      // topSelling.legend({
      //    position: 'top',
      // });
      topSelling.legend(false)
      topSelling.source(data, {
        percent: {
          formatter: val => {
            val = Math.ceil((val * 10000) / 1) / 100 + '%'
            return val
          }
        }
      })
      topSelling.coord('theta', {
        radius: 0.8,
        innerRadius: 0.6
      })
      topSelling.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      // 辅助文本
      topSelling.guide().html({
        position: ['50%', '50%'],
        html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">合计<br><span style="color:#8c8c8c;font-size:20px">${this.TotalTopSelling}</span></div>`,
        alignX: 'middle',
        alignY: 'middle'
      })
      let interval = topSelling
        .intervalStack()
        .position('percent')
        .color('item', COLOR_PLATE_16)
        .label('percent', {
          formatter: (val, item) => {
            return `${item.point.item}:\n ${val}`
          }
        })
        .tooltip('item*count', (item, count) => {
          // percent = Math.ceil((percent * 10000)/1)/100 + '%';
          return {
            name: item,
            value: count
          }
        })
        .style({
          lineWidth: 1,
          stroke: '#fff'
        })
      topSelling.render()
      interval.setSelected(data[0])
    },
    InventoryRatioFn (data) {
      let chart = new G2.Chart({
        container: 'InventoryRatio',
        forceFit: true,
        height: 300,
        animate: false
      })
      // chart.legend({
      //    position: 'top',
      // });
      chart.legend(false)
      chart.source(data, {
        percent: {
          formatter: val => {
            val = Math.ceil((val * 10000) / 1) / 100 + '%'
            return val
          }
        }
      })
      chart.coord('theta', {
        radius: 0.8,
        innerRadius: 0.6
      })
      chart.tooltip({
        showTitle: false,
        itemTpl:
          '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      // 辅助文本
      chart.guide().html({
        position: ['50%', '50%'],
        html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">合计库存<br><span style="color:#8c8c8c;font-size:20px">${this.StockNums}</span></div>`,
        alignX: 'middle',
        alignY: 'middle'
      })
      let interval = chart
        .intervalStack()
        .position('percent')
        .color('item', COLOR_PLATE_16)
        .label('percent', {
          formatter: (val, item) => {
            return `${item.point.item}:\n ${val}`
          }
        })
        .tooltip('item*count', (item, count) => {
          // percent = Math.ceil((percent * 10000)/1)/100 + '%';
          return {
            name: item,
            value: count
          }
        })
        .style({
          lineWidth: 1,
          stroke: '#fff'
        })
      chart.render()
      interval.setSelected(data[0])
    },
    moneyTotalFn (data) {
      let chart = new G2.Chart({
        container: 'moneyTotal',
        forceFit: true,
        height: 260,
        animate: false
      })
      chart.legend(false)
      chart.source(data, {
        percent: {
          formatter: val => {
            val = Math.ceil((val * 10000) / 1) / 100 + '%'
            return val
          }
        }
      })
      chart.coord('theta', {
        radius: 0.75,
        innerRadius: 0.7
      })
      chart.tooltip({
        showTitle: false,
        itemTpl:
          '<li style="width:120px;"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
      })
      // 辅助文本
      // chart.guide().html({
      // position: [ '50%', '50%' ],
      // html: `<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;">合计<br><span style="color:#8c8c8c;font-size:20px">${this.moneyTotal}</span>台</div>`,
      // alignX: 'middle',
      // alignY: 'middle'
      // });
      const interval = chart
        .intervalStack()
        .position('percent')
        .color('item', COLOR_PLATE_16)
        .label('percent', {
          formatter: (val, item) => {
            return `${item.point.item}:\n ${val}`
          }
        })
        .tooltip('item*count', (item, count) => {
          // percent = Math.ceil((percent * 10000)/1)/100 + '%';
          return {
            name: item,
            value: count
          }
        })
        .style({
          lineWidth: 1,
          stroke: '#fff'
        })
      chart.render()
      interval.setSelected(data[0])
    }
  }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  /*当屏幕尺寸小于1200px时，应用下面的CSS样式*/
  .mleft {
    margin-left: 0px !important;
  }
  .cars_one {
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 1200px) {
  /*当屏幕尺寸小于1200px时，应用下面的CSS样式*/
  .mleft {
    margin-left: 20px !important;
  }
  .cars_one {
    margin-left: 20px !important;
  }
}

.StockOverview {
  font-size: 14px;
  color: #666666;
  .flexBox {
    display: flex;
    justify-content: space-between;
  }
  .carBox {
    position: relative;
    color: #333333;
    & > .carBox {
      margin-left: 20px;
      width: 100%;
      & > span {
        left: 20px;
        top: 58px;
      }
    }
    & > span {
      color: #666666;
      font-size: 13px;
      position: absolute;
    }
    .progressBox {
      position: absolute;
      height: 24px;
      width: 100%;
      bottom: 0px;
      left: 0px;
      .bar {
        width: 100%;
        height: 5px;
        border-radius: 4px;
        background-color: #f5f5f5;
      }
      .progressBar {
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
    }
  }
  .box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
  }

  .chartBox {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px 28px;
    width: 100%;
    height: 400px;
  }
  .overBox {
    height: 310px;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    position: relative;
  }
  b {
    font-weight: bold;
    font-size: 18px;
  }
  .type_class {
    color: #666666;
    font-size: 14px;
    line-height: 24px;
  }
  .totalNums {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-top: 1px solid #ebebeb;
    height: 69px;
    line-height: 64px;
    text-indent: 28px;
    .nums {
      font-size: 30px;
      color: #333333;
    }
  }
  .groupBox {
    width: 100%;
    height: 60px;
    // text-indent: 2em;
    text-align: center;
    margin-top: 60px;

    div {
      font-size: 24px;
      color: #333333;
    }
  }
  #TopMoneySelling,
  #TopSelling {
    position: relative;
    margin-left: 248px;
  }
  #InventoryRatio {
    transform: translateX(-26%);
  }
  #moneyTotal {
    transform: translateX(-6%);
  }
  .widthBar {
    width: 18px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
  }
}
</style>
